const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.laika.forwoodsafety.com',
    USER_TOKEN_URL: 'https://rqiex4frje.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://vog8zzf5m9.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'teams-container-1.10.2',
    HOSTNAME: 'https://teams.dev.laika.forwoodsafety.com',
    TEAMS_MICROFRONTEND_URL: 'https://teams-service.dev.laika.forwoodsafety.com',
    TARGETS_MICROFRONTEND_URL: 'https://targets.dev.laika.forwoodsafety.com',
    TEAMS_ACCESS_ROLE: 'TeamsAccess',
    TEAM_TARGETS_ACCESS_ROLE: 'TeamTargetsAccess',
  },
  configurationService: {
    URL: 'https://config.dev.laika.forwoodsafety.com',
    WEBSOCKET: 'wss://1wnibn8oa1.execute-api.us-west-2.amazonaws.com/dev'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
